import React from 'react'
import Helmet from 'react-helmet'
import Pricing from '../Pricing'
import PropTypes from 'prop-types'
import prices from '../../assets/img/prices.jpg'

const PricingPageTemplate = ({
  title,
  meta_title,
  meta_description,
  pricing,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-three-fifths is-offset-one-fifth'>
              <div className='section'>
                <h1 className='title is-size-1 has-text-weight-bold'>
                  {title}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section>


      <div className='container box has-text-centered px-6'>
        
        <h2 className='is-size-3 has-text-weight-bold py-5'>A camel is a horse designed by committee</h2>
        <aside className='columns py-5 is-variable is-8'>

          <div className='column'>
            <h3 className='has-text-weight-bold is-size-5 mb-3'>A totally unique perspective</h3>
            <p>You trust me to deliver a fresh perspective – I'll trust you to interpret it and use it.</p>
          </div>

          <div className='column'>
            <h3 className='has-text-weight-bold is-size-5 mb-3'>Expert help for a fraction of the cost</h3>
            <p>You set the parameters through a form, and then I get creative (without you).</p>
          </div>

          <div className='column'>
            <h3 className='has-text-weight-bold is-size-5 mb-3'>100% unique to your startup</h3>
            <p>Not a few tips and tricks, or generic appraisal. Full creative exploration of your unique situation.</p>
          </div>

        </aside>

      </div>

    </section>

    <section className='section'>
      <div className='container'>
        <div className='column is-10 is-offset-1'>
          <div className='columns is-variable is-8'>
            <div className='column'>
              <img src={prices} />
            </div>
            <div className='content column'>
              <h2>You need to get creative with your marketing. <em>This is how startups do it.</em></h2>
              <p>From experience; the hardest and most costly part of any creative project is pandering to you.</p> 
              <p>Yes. You.</p> 
              <p>Unlimited emails, lengthy meetings, 'Yeah, no problem' amendments.</p>
              <p>This is not what you need.</p>
              <ul>
                <li>You need expert creative help that understands your world</li>
                <li>You need to stand out and gain more traction with your audience</li>
                <li>You need people to get up to speed quickly</li>
                <li>You need autonomous decisions, so you're not 'blocking' progress</li>
                <li>You need great value for money</li>
              </ul>

              <p>This is how you get that without spending £000's.</p>

              <p><strong>Because you're not buying a process, you're buying a result.</strong></p>

              <p>No meetings + no monthly subscription + no bullsh*t.</p>
              <p>Just superstar creative, at a fraction of the cost.</p>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

PricingPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

export default PricingPageTemplate
